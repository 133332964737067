html,
body,
#root {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  color: #1b4332;
  font-family: "Cabin", sans-serif;
  text-align: center;
  height: 100%;
}
h1 {
  font-size: 70px;
}
.Grid {
  display: grid;
  background-color: #74c69d;
  /* border: #1b4332 solid 1px; */
  border-radius: 2%;
  margin: auto;
  width: 400px;
  height: 400px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.Tile {
  justify-self: stretch;
  align-self: stretch;
  /* background-color: #b7e4c7; */
  border-radius: 10%;
  /* border: #1b4332 solid 1px; */
  margin: 10px;
  text-align: center;
  /* font-size: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.onTopOfGrid {
  display: flex;
  padding: 20px;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.Score {
  padding: 15px;
  background-color: #b7e4c7;
  display: flex;
  width: 150px;
  border-radius: 5%;
  font-size: 20px;
}
.resetButton {
  padding: 15px;
  background-color: #b7e4c7;
  display: flex;
  width: 100px;
  border-radius: 5%;
  font-size: 20px;
  justify-content: center;
}
.resetButton:hover {
  cursor: pointer;
}
.HiScore {
  padding: 15px;
  background-color: #b7e4c7;
  display: flex;
  width: 200px;
  border-radius: 5%;
  font-size: 20px;
}
.filler_out {
  display: flex;
  height: 90%;
}
.filler_in {
  flex-grow: 1;
}
.signature {
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  margin: auto;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .HiScore {
    font-size: 15px;
    padding: 10px;
    width: 150px;
    align-items: center;
  }
  .Score {
    font-size: 15px;
    padding: 15px;
    width: 100px;
  }
  .resetButton {
    font-size: 15px;
    padding: 15px;
  }
}
